<template>
  <div  class="px-15px px-lg-25px">
    
    <div class="card">
      <div class="card-header">
          <h5 class="mb-0 h6">{{ $t('xin-yong-fen-li-shi') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('ri-qi') }}</th>
              <th>{{ $t('bian-dong-qian-shu-liang') }}</th>
              <th>{{ $t('bian-dong-hou-shu-liang') }}</th>
              <th>{{ $t('bei-zhu') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>
                  {{ i + 1 }}
              </td>
              <td>{{item.CreateAt}}</td>
              <td>100</td>
              <td>90</td>
              <td>{{item.Remark}}</td>
            </tr>
            </tbody>
        </table>
        <div class="aiz-pagination">
          <pageBar :totalPage="56" :page="51"></pageBar>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
export default {
  components: {
    pageBar
  },
  data() {
    return {
      hasPackage: true,
      packageUrl: require('../../../assets/imgs/package.png'),
      tableData: [],
    }
  }
}
</script>